var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(_vm._s(_vm.$t("amendment-of-will")))])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4 col-md-4"
  }, [_c('div', {
    staticClass: "sidebar sticky-bar p-4 rounded shadow bg-light"
  }, [_c('div', {
    staticClass: "widget mb-4 pb-4 border-bottom"
  }, [_c('div', {
    staticClass: "widget-search mt-4 mb-0",
    attrs: {
      "id": "jobkeywords"
    }
  }), _c('router-link', {
    staticClass: "btn w-100",
    class: _vm.pindaanPart >= 1 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_a',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " A ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Wasiat")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 2 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_b',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " B ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("testator")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 3 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_c',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " C ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("beneficiary")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 4 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_d-tidakalih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " D ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("property")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 5 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_e',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " E ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("caretaker")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 6 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_f',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " F ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("message")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 7 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_g',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " G ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("document")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 8 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_h',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " H ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("verification")))])]), _vm.allowsaksi ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 9 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.part_i',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " I ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("witness")))])]) : _vm._e(), _vm.amendment_status == 2 ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.pindaanPart >= 10 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "to": {
        name: 'pindaan.draft-wasiat',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("draft-final-will")))])]) : _vm._e()], 1)])]), _c('router-view')], 1)])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }